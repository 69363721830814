/*const filterForm = document.getElementById('js-form-filter');

if (filterForm) {
  const filterOptions = document.querySelectorAll('.js-filter-option');
  const formInputs = Array.prototype.slice.call(filterOptions);

  if ( filterOptions ) {
    for (var i = 0; i < formInputs.length; i += 1) {
      formInputs[i].addEventListener('change', function() {
        filterForm.submit();
      });
    }
  }
}*/


(function($){
  var postFilter = {
    get_url: window.location.href,
  onSubmit:function(e){
    var pageNumber = $("#more_posts").attr('data-pagenumber');
    var ppp = $("#more_posts").attr('data-ppp');
    var taxonomy = $("#more_posts").attr('data-taxonomy');
    var posttype = $("#more_posts").attr('data-posttype');
    var posttaxonomy = $("#more_posts").attr('data-posttaxonomy');

    var form = $('#js-form-filter');
    var type = $('#post_type').val();
    var current_page = $('#current_page').val();
    var data = { 'filter-categories[]' : []};
    $("input:checked").each(function() {
    data['filter-categories[]'].push($(this).val());
    pageNumber = 1;
    if($(this).val() == 0){
      $.each($('.js-filter-option:checked'), function() {
          $(this).parent().toggleClass('checked');
      });
      $('#js-form-filter')[0].reset();
    }

    });

    var term = [];
    $.each($('.js-filter-option:checked'), function() {
      term.push($(this).val());
    });
    $.ajax({
			type: "POST",
			url: "/wp-admin/admin-ajax.php",
			dataType: "html",
			data: {
				action: "filter_case_studies",
        type: type,
        term: term,
        page: current_page,
        ppp: ppp,
        pageNumber: pageNumber,
        taxonomy: taxonomy,
        posttype: posttype,
        posttaxonomy: posttaxonomy
			},
			success: function(res) {
				$(".news-list-external__wrapper").html(res);
			},
      error: function(xhr, ajaxOptions, thrownError) {
        alert(thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText);
      }
		});
    return false;
  }
};

function change_page(){
  var ppp = $("#more_posts").attr('data-ppp');
  var pageNumber = $("#more_posts").attr('data-pagenumber');
  var taxonomy = $("#more_posts").attr('data-taxonomy');
  var posttype = $("#more_posts").attr('data-posttype');
  var posttaxonomy = $("#more_posts").attr('data-posttaxonomy');
  var form = $('#js-form-filter');
  var type = $('#post_type').val();
  var current_page = $('#current_page').val();
  var data = { 'filter-categories[]' : []};
  $("input:checked").each(function() {

  data['filter-categories[]'].push($(this).val());
  });

  var term = [];
  $.each($('.js-filter-option:checked'), function() {
    term.push($(this).val());
  });
  $.ajax({
			url: "/wp-admin/admin-ajax.php",
			dataType: "html",
      type: "POST",
			data: {
				action: "filter_case_studies",
        type: type,
        term: term,
        page: current_page,
        ppp: ppp,
        pageNumber: pageNumber,
        taxonomy: taxonomy,
        posttype: posttype,
        posttaxonomy: posttaxonomy
			},
      success: function(res){
            $(".news-list-external__wrapper").html(res);
      },
      error: function(xhr, ajaxOptions, thrownError) {
        //alert(thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText);
      }

  });
  return false;
}

$("body").on("click", ".page-numbers", function(){ // When pagination link is pressed.
  $("#more_posts").attr('data-pagenumber', $(this).attr('data-page')); // Set page number
  change_page();
});


$(function(){
  $('#js-form-filter').on('change', ':checkbox', function(){
          $(this)
          .parent()
          .toggleClass('checked');
          $(this)
          .closest('form')
          .submit();
        });
        $('#js-form-filter').on('submit', function(e){
          e.preventDefault();
          postFilter.onSubmit();
        });
 });

 window.postFilter = postFilter;

})(jQuery);
